import { Button } from "antd";
import { useState } from "react";
import "./ButtonCustom.css";

const ButtonCustom = ({ onClick, text, loading }: any) => {
  const [dis, setDis] = useState(false);
  // var timeOut: any;

  const handleClick = () => {
    if (!dis) {
      onClick();
      setDis(true);
      setTimeout(() => {
        setDis(false);
      }, 900);
    }
  };

  return (
    <Button
      className="comon-button"
      loading={loading}
      type="primary"
      onClick={handleClick}
      style={{ backgroundColor: "#f8a83c" }}
    >
      <div className="but-text">{text}</div>
    </Button>
  );
};

export default ButtonCustom;
