import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Redirect } from "react-router-dom";
import * as API from "../../Services/API";
import * as URL from "../../config/config";
import { useHistory } from "react-router";

import "./styles.css";
// const Trello = require("trello");

// interface IProps {
//   AppKey: string;
//   UserToken: string;
//   cardId: string;
// }

// const AppKey = "e194b260140d1463ca451a7066723ff5";
// const UserToken =
// "00fb7382aae9ba223894e5146d972ddef00b93c6734e3bf0a8821a38ed08e408";
// const myListId = "61a5ad2f19707b278ce3a1fb";
// const cardId = "61a5ad3bd608ac5e0d870f8b";
// const trello = new Trello(AppKey, UserToken);

// var infor = JSON.parse(localStorage.getItem("infor")!);
const AppKey = localStorage.getItem("AppKey");
const UserToken = localStorage.getItem("UserToken");
const CardId = localStorage.getItem("CardId");

const copyTable = () => {
  const elTable = document.querySelector("table");

  let range, sel: any;

  // Ensure that range and selection are supported by the browsers
  if (document.createRange && window.getSelection) {
    range = document.createRange();
    sel = window.getSelection();
    // unselect any element in the page
    sel.removeAllRanges();

    try {
      range.selectNodeContents(elTable!);
      sel.addRange(range);
    } catch (e) {
      range.selectNode(elTable!);
      sel.addRange(range);
    }

    document.execCommand("copy");
  }

  sel.removeAllRanges();

  console.log("Element Copied! Paste it in a file");
};
async function beginUploadFile(index: number, file: File) {
  var formData = new FormData();

  formData.append("token", String(UserToken));
  formData.append("key", String(AppKey));

  // HTML file input, chosen by user
  formData.append("file", file);

  // var request = new XMLHttpRequest();
  // request.open("POST", "https://api.trello.com/1/cards/" + cardId + "/attachments");
  // request.send(formData);
  try {
    const response = await fetch(
      `https://api.trello.com/1/cards/${CardId}/attachments`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      }
    );
    console.log(`Response: ${response.status} ${response.statusText}`);
    const result = await response.json();
    // {
    //   "id": "61a74a2d21867e2185d80489",
    //   "bytes": 175658,
    //   "date": "2021-12-01T10:10:53.647Z",
    //   "edgeColor": "#fbfbfc",
    //   "idMember": "5e574c90df5cf90d43a21eb2",
    //   "isUpload": true,
    //   "mimeType": "image/png",
    //   "name": "Screen Shot 2021-11-22 at 06.43.57.png",
    //   "previews": [
    //       {
    //           "id": "61a74a2e21867e2185d804c7",
    //           "_id": "61a74a2e21867e2185d804c7",
    //           "scaled": true,
    //           "url": "https://trello.com/1/cards/61a5ad3bd608ac5e0d870f8b/attachments/61a74a2d21867e2185d80489/previews/61a74a2e21867e2185d804c7/download/Screen_Shot_2021-11-22_at_06.43.57.png",
    //           "bytes": 99333,
    //           "height": 237,
    //           "width": 1200
    //       },
    //       {
    //           "id": "61a74a2e21867e2185d804c8",
    //           "_id": "61a74a2e21867e2185d804c8",
    //           "scaled": true,
    //           "url": "https://trello.com/1/cards/61a5ad3bd608ac5e0d870f8b/attachments/61a74a2d21867e2185d80489/previews/61a74a2e21867e2185d804c8/download/Screen_Shot_2021-11-22_at_06.43.57.png",
    //           "bytes": 175658,
    //           "height": 496,
    //           "width": 2516
    //       }
    //   ],
    //   "url": "https://trello.com/1/cards/61a5ad3bd608ac5e0d870f8b/attachments/61a74a2d21867e2185d80489/download/Screen_Shot_2021-11-22_at_06.43.57.png",
    //   "pos": 163840,
    //   "fileName": "Screen_Shot_2021-11-22_at_06.43.57.png",
    //   "limits": {}
    // }
    console.log(new Date(), `upload file ${index + 1} result`, result);

    return { imgUrl: result.url };
  } catch (error) {
    console.error(error);
    alert("upload file error");
  }

  return {};
}

function getCategoryOfFilePath(path: string) {
  const lastIndex = path.lastIndexOf("/");
  let cat = path.slice(0, lastIndex);
  if (cat.startsWith("/")) {
    cat = cat.slice(1);
  }
  console.log("cat", path, cat);
  return cat;
}

async function beginUploadFiles(
  // cats: any[],
  acceptedFiles: File[],
  callback: any
) {
  // tính các category
  const catSet = new Set<string>();
  acceptedFiles.forEach((file: any) => {
    const cat = getCategoryOfFilePath(file.path);
    catSet.add(cat);
  });

  const catArr = Array.from(catSet.values()).map((catName) =>
    Object({
      name: catName,
      items: [],
    })
  );
  console.log("catArr", catArr);

  for (let i = 0; i < acceptedFiles.length; i++) {
    const file: any = acceptedFiles[i];
    const { imgUrl } = await beginUploadFile(i, file);

    const cat = getCategoryOfFilePath(file.path);
    const catIndex = catArr.findIndex((i) => i.name === cat);
    catArr[catIndex].items.push(imgUrl);
    catArr[catIndex].items.sort((a: string, b: string) => a.localeCompare(b));

    // uploadResults.push({ imgUrl, cat });
    console.log(new Date(), "uploadFiles", { uploadResults: catArr });

    callback(i + 1, acceptedFiles.length, imgUrl, catArr);
  }
  return catArr;
}

const Upload = () => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [uploadButtonDisable, setUploadButtonDisable] = useState(false);
  const [remainingUploadFiles, setRemainingUploadFiles] = useState(0);
  const [cats, setCats] = useState<any[]>([]);
  const history = useHistory();

  if (!AppKey || !UserToken || !CardId) {
    return <Redirect to="./auth" />;
  }

  const files = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      {file.path} -{" "}
      {(file.size / 1000).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      })}{" "}
      KB
    </li>
  ));

  return (
    <div className="main-bg">
      <div className="upload">
        <div className="top-part">
          <i
            // style={{ marginRight: 20 }}
            className="fa fa-sign-out fa-2x"
            aria-hidden="true"
            onClick={() => {
              localStorage.clear();
              // window.location.href = "./auth";
              // window.location.href = `${process.env.PUBLIC_URL}/auth`;
              history.push(`/auth`);
            }}
          ></i>
        </div>
        <div className="tittle-container">
          <div className="title">UPLOAD YOUR FILE</div>
          <div className="sub-title">File should be png or jpg</div>
        </div>
        <section className="container">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <i
              className="fas fa-folder-open"
              style={{ fontSize: "50px", color: "rgb(248, 168, 60)" }}
            ></i>
            <p>Drag and drop your folder or files here</p>
          </div>
        </section>
        <h4>Danh sách file</h4>
        <aside className="list-file">
          <ul>{files}</ul>
        </aside>
        <button
          disabled={uploadButtonDisable}
          onClick={async () => {
            if (acceptedFiles.length === 0) {
              return alert("Select files to upload");
            }
            let verifyUsertoken = await API.HandleMethodGet(
              `${URL.VERIFYTOKEN}/${UserToken}`
            );
            console.log("verifyUsertoken============>", verifyUsertoken.us);
            if (!verifyUsertoken || verifyUsertoken.us === null) {
              return alert("Invalid usertoken...");
            }
            setCats([]);
            setRemainingUploadFiles(acceptedFiles.length);
            setUploadButtonDisable(true);
            beginUploadFiles(
              acceptedFiles,
              (
                proccessed: number,
                totalFiles: number,
                imgUrl: string,
                uploadResults: any[]
              ) => {
                console.log(new Date(), { proccessed, totalFiles, imgUrl });
                if (proccessed === totalFiles) {
                  setUploadButtonDisable(false);
                }
                setRemainingUploadFiles(acceptedFiles.length - proccessed);

                console.log(
                  new Date(),
                  "setResults",
                  proccessed,
                  uploadResults.map((i) => i.imgUrl)
                );

                setCats(uploadResults);
              }
            );
          }}
        >
          {acceptedFiles.length > 0
            ? `Upload ${acceptedFiles.length} files`
            : `Select files to upload`}
        </button>
        <div className="rmn">remainingUploadFiles: {remainingUploadFiles}</div>
        <h4>Result</h4>{" "}
        <button
          id="copybtn"
          onClick={() => {
            copyTable();
          }}
        >
          Copy to table
        </button>
        <aside className="list-result">
          {/* <ul>{results}</ul> */}
          <table>
            {/* <thead>
              <tr>
                <th>First name</th>
                <th>Last name</th>
                <th>Password</th>
                <th>Email</th>
              </tr>
            </thead> */}
            <tbody>
              {cats.map((cat) => {
                return (
                  <tr key={cat.name}>
                    <td>
                      <b>{cat.name}</b>
                    </td>
                    {cat.items.map((item: string) => (
                      <td>{item}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </aside>
      </div>
    </div>
  );
};

export default Upload;
