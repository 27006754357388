import React from "react";
import "./App.css";
import Upload from "./components/Upload/Upload";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Authenticate from "./components/Auth/Authentication";

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route path="/auth" component={Authenticate} />
        <Route path="/" component={Upload} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
