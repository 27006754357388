import React, { useState } from "react";
import InputField from "../InputFormCustom/InputField";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "./auth.css";
import ButtonCustom from "../Buttons/ButtonCustom";
import { useHistory } from "react-router";

const schema = yup.object().shape({
  AppKey: yup.string().required("Required field."),
  UserToken: yup.string().required("Required field."),
  CardId: yup.string().required("Required field."),
  // note: yup.string().required(''),
});

// interface IProps {
//     AppKey: string;
//     UserToken: string;
//     cardId: string;
//   }

const Authenticate = () => {
  // const history = useHistory();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const {
    handleSubmit,
    control,
    // setError,
    formState: { errors },
    // setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (values: any) => {
    setLoading(true);
    // console.log("Success:", values);
    setTimeout(() => {
      localStorage.setItem("AppKey", values.AppKey);
      localStorage.setItem("UserToken", values.UserToken);
      localStorage.setItem("CardId", values.CardId);
      //   localStorage.setItem("infor", values);
      setLoading(false);
      console.log(new Date(), "history", history);
      console.log(new Date(), "goto process.env.PUBLIC_URL", process.env.PUBLIC_URL);
      window.location.href = `${process.env.PUBLIC_URL}`;
      // history.push('/');
    }, 2000);
  };

  return (
    <div className="bg">
      <div className="frame">
        <div className="title">CONFIRM INFOR POPUP</div>

        <div style={{ marginTop: 30 }} className="frame-input">
          <div style={{ marginTop: 10 }}>
            <span style={{ color: "red" }}>* </span>AppKey:
          </div>
          <section className="mb-4" style={{ paddingLeft: "0px" }}>
            <InputField
              control={control}
              nameInput="AppKey"
              placeholder="AppKey"
              validateStatus={errors.AppKey?.message}
              type="text"
            />
          </section>
        </div>

        <div style={{ marginTop: 30 }} className="frame-input">
          <div style={{ marginTop: 10 }}>
            <span style={{ color: "red" }}>* </span>UserToken:
          </div>
          <section className="mb-4" style={{ paddingLeft: "0px" }}>
            <InputField
              control={control}
              nameInput="UserToken"
              placeholder="UserToken"
              validateStatus={errors.UserToken?.message}
              type="text"
            />
          </section>
        </div>

        <div
          style={{ marginTop: 30, marginBottom: 20 }}
          className="frame-input"
        >
          <div style={{ marginTop: 10 }}>
            <span style={{ color: "red" }}>* </span>CardId:
          </div>
          <section className="mb-4" style={{ paddingLeft: "0px" }}>
            <InputField
              control={control}
              nameInput="CardId"
              placeholder="CardId"
              validateStatus={errors.CardId?.message}
              type="text"
            />
          </section>
        </div>

        <div className="frame-button">
          <ButtonCustom
            onClick={handleSubmit(onSubmit)}
            text={"Xác nhận"}
            loading={loading}
          ></ButtonCustom>
        </div>
      </div>
    </div>
  );
};

export default Authenticate;
